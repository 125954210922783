import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Layout } from "../../layouts/Layout";
import { BlobPostProps } from "../../utilities/types/blog/types";
import { elevation, below } from "../../utilities";

export const query = graphql`
  query BlogPostQuery($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      body {
        childMarkdownRemark {
          excerpt
          timeToRead
          html
        }
      }
    }
  }
`;

const PostPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const PostWrapper = styled.section`
  margin-top: 8px;

  hr {
    margin: 16px 24px;
    width: 100%;
    border: 2px solid ${(props) => props.theme.colors.site.primary20};
    border-radius: 5px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 24px;
  width: 60%;
  p {
    margin-bottom: 1.2rem;
  }
  :last-child {
    padding-bottom: 0;
  }
  ${below.medium`
    width: 100%;
  `}
`;

const PostBody = styled.article`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${below.medium`
    align-items: center;    
  `};

  img {
    display: block;
    width: 50%;
    border-radius: 8px;
    margin: 8px 0;
    ${elevation[3]};
    ${below.medium`
      align-self: center;
      width: 85%;
  `}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${(props) => props.theme.colors.text.heading80};
  }
`;

const PostTitle = styled.h1`
  width: 100%;
  text-align: center;
  font-family: "Roboto-Light";
`;

const ReadPost = ({ data }: BlobPostProps) => {
  return (
    <Layout>
      <PostPageContainer>
        <PostWrapper>
          <PostTitle>{data.contentfulBlogPost.title}</PostTitle>
          <hr />
          <PostBody
            dangerouslySetInnerHTML={{
              __html: data.contentfulBlogPost.body.childMarkdownRemark.html,
            }}
          ></PostBody>
        </PostWrapper>
      </PostPageContainer>
    </Layout>
  );
};

export default ReadPost;
